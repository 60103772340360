@import "variables";
@import "generic";

.btn-info {}



/****** LOGIN SCREEN
****************************************************************************************************************/
.topHead-info,.login-nav,.login-sec{
  .container{ max-width:1400px;}
}
.topHead-info{ color:#FFF; background-color:$brand-primary-gray;}
.login-sec{ padding:0;
  background-size:cover; background-repeat:no-repeat; background-position:center;
  background-image: url('./assets/images/login-bg.jpg');
  .login-text{ font-size:46px; color:#FFF; margin:0;}
  .sub-text{ color:#FFF; margin:1em 0 0; font-family:"gotham-book", sans-serif;}
  .reprise-products img{ max-width:160px; width:100%;}
  .spinner-border{ font-size:10px; width:18px; height:18px; vertical-align:middle;}
  .trademark-body{ max-width:1400px; margin:auto;}
  .forgot-formlabel .form-label{ justify-content:start!important;}
  @media screen and (max-width:1000px){
    .login-text{ font-size:30px;}
    .sub-text{ margin:.5em 0 1em;}
    .login-sub-text{margin-top:1.25em;}
  }
  .reset-formlabel .form-label{ justify-content:start!important;}
}


.page-link{ color:#000;}

.prescribing-accordion{
  .accordion-item{ border:0;}
  .accordion-header{
    .accordion-button{ color:$brand-secondary-orange; background:#1F1F1F; border-radius:0 !important; font-size:14px;
      justify-content:center;
      &:focus{ box-shadow:none;}
      &:after{ display:none;}
    }
  }
  @media screen and (max-width:1000px){
    .accordion-body{ max-height:450px; overflow:auto;}
  }
}

.cases-page{
  .table{
    thead{ position:sticky; top:0;}
  }
}
