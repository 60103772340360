@font-face{
  font-family: "gotham-light";
  src: url("./assets/fonts/gotham-light.woff") format("woff");
}
@font-face{
  font-family: "gotham-book";
  src: url("./assets/fonts/gotham-book.woff") format("woff");
}
@font-face{
  font-family: "gotham-medium";
  src: url("./assets/fonts/gotham-medium.woff") format("woff");
}
@font-face{
  font-family: "gotham-bold";
  src: url("./assets/fonts/gotham-bold.woff") format("woff");
}

html,body{ height:100%; display:flex; flex-direction:column;
  main{ display:flex; flex-direction:column; flex:1; overflow:hidden;}
}
body {
  font-family:Arial; font-size:15px;
  -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
}
h1,h2,h3,h4,h5,h6{ font-family:"gotham-bold", Arial;}

/****** TABLE
****************************************************************************************************************/
.smallTable,table,th,td{ font-size:14px;}
.table-striped>tbody>tr:nth-of-type(odd)>*{ box-shadow:none; background-color:#f6f6f6;}
.background-color-1{ background-color:#00AEEB !important;}
.background-color-2{ background-color:#0074BE !important;}
.background-color-3{ background-color:#28a745 !important;}
.background-color-4{ background-color:#d9534f !important;}
.background-color-5{ background:#FFB600 !important;}

/****** HEADER
****************************************************************************************************************/
header{ border-bottom:1px solid #DDD;
  .navbar-brand{
    img{ max-width:250px; width:100%;}
    .after-login-logo{ max-width:250px;}
    @media screen and (max-width:992px){
      flex:1;
    }
  }
  .navbar-toggler{ padding:4px 8px; box-shadow:none !important;}
  .first-nav{
    li:nth-child(2){
      .nav-link{ background-color:#5BC2E7; border-radius:100px; border:1px solid #5BC2E7; color:#FFF; padding:8px 15px;
        &:hover{ background-color:#FFF; color:#5BC2E7;}
      }
    }
  }
  .nav-link{ font-size:17px; font-family:"gotham-medium", arial;}
  .colors-dropdown{ min-width:360px;}
  .resources-dropdown{ min-width:405px;}
  .dropdown-item{ text-wrap:balance;}
  @media screen and (max-width:992px){
    .navbar-collapse{ max-height:450px; overflow:auto;}
    .colors-dropdown{ min-width:auto;}
    .resources-dropdown{ min-width:auto;}
  }
}

/****** BUTTONS
****************************************************************************************************************/
.btn{ font-size:15px;
  &:hover:not(.btn-outline-secondary, .btn-link){ background-color:#FFF;
    &.btn-primary{ color:$brand-primary;}
    &.btn-secondary{ color:#6c757d;}
    &.btn-success{ color:$brand-secondary-green;}
    &.btn-danger{ color:#dc3545;}
    &.btn-purple{ color:#611146;}
  }
}
.btn-primary{ border-color:$brand-primary; background:$brand-primary;}
.btn-success{ border-color:$brand-secondary-green; background:$brand-secondary-green;}

/****** FORM CONTROLS
****************************************************************************************************************/
.form-control{
  &[type="date"]{ position:relative;
    &::-webkit-calendar-picker-indicator{ background:transparent;  color:transparent; cursor:pointer; width:100%;
      position:absolute; top:8px; bottom:0; right:10px; z-index: 1;
    }
    &:after{ content:"\F1F6"; font-family:bootstrap-icons; position:absolute; top:6px; right:12px;}
  }
}
.form-label{ display:flex; align-items:center; justify-content:end;
  max-height:37px; margin:0; font-weight:bold; text-align:right;
  @media screen and (max-width:992px){
    justify-content:start; text-align:left; max-height:none;
  }
}

/****** BRANDING COLORS
****************************************************************************************************************/
.text-primary{ color:$brand-primary !important;}
.text-secondary{ color:$brand-secondary;}
.text-secondary-green{ color:$brand-secondary-green;}
.text-secondary-orange{ color:$brand-secondary-orange;}
.text-link{ color:$brand-primary;}
.bg-primary{ background-color:$brand-primary !important;}
.bg-primary-gray{ background-color:$brand-primary-gray !important;}
.bg-gradiant-primary{ background: linear-gradient(90deg,$brand-primary 0%,$brand-tertiary 100%);}

/****** CARDS
****************************************************************************************************************/
.card-header{ background-color:#e0f1f9;
  h3,h4{ font-size:20px;}
}

/****** PAGINATION
****************************************************************************************************************/
.pagination{
  .page-item.active{
    .page-link{ background-color:$brand-primary; z-index:auto;}
  }
}


.case-progress-bar{ overflow:hidden; border-radius:7px; display:flex; background-color:#d3d3d3;
  .case-creation-progress-step{ background:transparent; position:relative;
    flex:1; border-radius:0; border:0; padding:6px 4%;
    &:hover{ background-color:#c4c4c4;}
    .progression-arrow-head{ color:transparent; font-size:70px; z-index:1;
      position:absolute; left:100%; top:0; bottom:0; display:flex; align-items:center;
      &:before{ text-indent:-22px;}
      @media screen and (max-width:768px){
         font-size:50px;
      }
    }
    &.active-progress-step{ background-color:$brand-primary; color:#FFF;
      .progression-arrow-head{ color:$brand-primary;}
    }
    &.invalid-progress-step{ background-color:$brand-secondary-orange; color:#FFF;
      .progression-arrow-head{ color:$brand-secondary-orange;}
    }
  }
}

.after-login-trademark{
  .copyright-text{ text-align:right !important;}
}
